




@media (max-width: 640px) {

.nav-content {
    font-size: 1rem !important;
}


}