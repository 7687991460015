.contacto-main {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url("../img/bg/19.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* box-shadow: -0px -100px 100px  10px rgba(0, 0, 0, 0.5) inset; */
    height: 700px;



}

.contact-row {
    height: 600px;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 2px 2px 4px #000000;
    backdrop-filter: blur(2px);
}




@media (max-width: 640px) {
    .logo-footer {
        margin: 50px;
        margin-left: 35vw;


    }

    .footer-products {
        display: none;
    }
}