

.custom-card-img{
        object-fit:cover !important;
        height: 240px;
        filter: brightness(45%);
}


.prod-button{
    position: absolute;
    bottom: 20px;
}


.card-title{

margin-top: 20px !important;

}

.card{
        height: 240px !important;
        /* width: 340px !important; */
      
        box-shadow: 10px 10px 10px rgba(89, 90, 90, 0.4);
        transition: all 1s ease;
        margin: 0px;
}

.card:hover{
    filter: brightness(120%);
        box-shadow: 10px 10px 10px rgba(89, 90, 90, 0.8);
        transition: all 1s ease;
        transform: scale(1.01);
}
.product-click:hover{
    cursor: pointer;
}

.product-cont-title{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), linear-gradient(.25turn, #3c4662, #727d9c);
    padding-top: 10px;
    padding-bottom: 10px;
}


.alcmodal{
    border-radius: 10px;
    margin: 10px;
}


.producto-main{
        background-image: linear-gradient(.25turn, #f4f5f8, #e6e6e9);
}





@media (max-width: 640px) {
    .card {
    
        height: 160px !important;
     
    }
    .prod-button {
        font-size: 13px !important;
    }
    .card-title {
        margin-bottom: 0px !important;
        margin-top: 10px !important;
        font-size: 16px !important;
    }
    .card-img-top {
        height: 180px ;

    }
    .alcmodal{
        width: 120px !important;
        height: 120px !important;
    }
    .card-text{
        display: none;
    }
}